<template>
  <div class="wrap">
    <div class="pc-warning">Невозможно отобразить содержимое страницы на вашем разрешении. Пожалуйста, откройте ссылку со смартфона</div>
    <div class="roulette-wrapper" :class="{'hidden-roulette': showWinning  }">
      <Roulette ref="wheel" :items="fakeItems" :base-display="true" :base-size="120"
         indicator-position="top" :horizontal-content="true" :display-indicator="true"
        :size="208" @wheel-end="wheelEndedCallback" @wheel-start="removeBtn" :wheelResultIndex="winningIndex" :duration="3"/>

      <div class="indicator"></div>
      <button class="wheel-btn" @click="startWrapper" >Крутить</button>
    </div>
    <transition appear
      name="fade-blur"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <WinWrapper v-if="showWinning && winning"  :winning="winning" @wheel-more="startWrapper"/>
    </transition>

    <img v-if="!showWinning && !winning" src="./assets/logo.png" alt="" class="logo">
  </div>
</template>

<script>
import { Roulette } from "vue3-roulette";
import WinWrapper from './components/WinWrapper'
import items from '@/json/items'
export default {
  name: "App",
  components: {
    Roulette, WinWrapper
  },
  data() {
    return {
      items: items,
      fakeItems: [
        { id: 1, name: "", htmlContent: "", textColor: "", background: "#051E59" },
        { id: 2, name: "", htmlContent: "", textColor: "", background: "#134D90" },
        { id: 3, name: "", htmlContent: "", textColor: "", background: "#FEFEFE" },
        { id: 4, name: "", htmlContent: "", textColor: "", background: "#051E59" },
        { id: 5, name: "", htmlContent: "", textColor: "", background: "#134D90" },
        { id: 6, name: "", htmlContent: "", textColor: "", background: "#FEFEFE" },
        { id: 7, name: "", htmlContent: "", textColor: "", background: "#051E59" },
        { id: 8, name: "", htmlContent: "", textColor: "", background: "#134D90" },
        { id: 9, name: "", htmlContent: "", textColor: "", background: "#FEFEFE" },
        { id: 10, name: "", htmlContent: "", textColor: "", background: "#051E59" },
        { id: 11, name: "", htmlContent: "", textColor: "", background: "#134D90" },
        { id: 11, name: "", htmlContent: "", textColor: "", background: "#FEFEFE" },
      ], 
      showWinning: false,
      winning: null,
      winningIndex: { value: 1 },
      reseted: false
    };
  },
  methods: {
    startWrapper() {
      this.showWinning = false
      this.reseted = false
        if(this.winning) {
          document.querySelector('.hidden-roulette').style.bottom = '38px'
          this.winning = null
        } 
        this.$refs.wheel.launchWheel();
    },
    removeBtn() {
      setTimeout(() => {
        document.querySelector('.wheel-btn').style.opacity = '0'
      }, 1000);
    },
    wheelEndedCallback() {
      if(!this.reseted) {
        const maxIndex = items.length - 1
        const randomID = Math.floor(Math.random() * maxIndex)
        this.winning = this.items[randomID]
        this.winningIndex.value = randomID
      }
      this.showWinning = true
    },
    beforeEnter() {
      document.querySelector('.hidden-roulette').style.opacity = '0'
      setTimeout(() => {
        document.querySelector('.hidden-roulette').style.opacity = '1'
      }, 3000);
        document.querySelector('.hidden-roulette').style.bottom = '-250px'
        this.$refs.wheel.reset()
        this.reseted = true
    },
    afterLeave() {
      document.querySelector('.roulette-wrapper').style.bottom = '38px'
    }
  },
}; 
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@media only screen and (min-width: 208px) 
  .fade-blur-enter-active,
  .fade-blur-leave-active 
    transition: opacity 0.8s ease, filter 0.8s ease
  

  .fade-blur-enter-from,
  .fade-blur-leave-to 
    opacity: 0
    filter: blur(10px)
  

  .fade-blur-enter-to,
  .fade-blur-leave-from 
    opacity: 1
    filter: blur(0)
  
  body
    margin: 0
    padding: 0
    font-family: "Roboto", sans-serif 
    background-image: url(./assets/page_bg.png)
    background-size: cover 
    background-position-x: center
    background-repeat: no-repeat
    max-height: 100svh
    height: 100svh
    overflow: hidden
    .wrap, #app
      max-height: 100svh
      height: 100svh
      overflow: hidden
  .pc-warning
    display: none
  .logo
    display: block
    position: absolute
    top: 48px
    left: 50%
    transform: translateX(-50%)
  .roulette-wrapper
    display: flex
    flex-direction: column
    position: absolute
    bottom: 38px
    left: 50%
    transform: translateX(-50%)
    transition: .5s ease-in-out
    border-radius: 50%
    align-items: flex-start
    .wheel-container
      position: relative
      border: 5px solid #fff
      overflow: visible
      z-index: 1
      &::after
        content: ''
        background-color: #001E38
        position: absolute
        left: -10px
        top: -10px
        width: calc(100% + 20px)
        height: calc(100% + 20px)
        z-index: -1
        border-radius: inherit
      &::before
        display: none
      .wheel-item
        border: 1px solid rgba(0,0,0,0)
        span
          font-size: 20px
          display: flex !important
          flex-direction: column
          align-items: flex-end
          margin-left: 15%
          margin-top: -40%
          .main
            font-size: 1.8125rem
            font-weight: 800
            text-transform: uppercase
          .no-main
            font-weight: 600
            font-size: 1.125rem
      .wheel-base-container
        border: none 
    .indicator
      position: absolute
      width: 10px
      height: 17px
      background-image: url(./assets/arrow.svg)
      background-size: contain
      background-repeat: no-repeat
      background-position: center
      top: 0
      left: 50%
      transform: translateX(-50%)
      z-index: 1
    .wheel-btn
      margin-top: 17px
      background-color: #F8B214
      padding: 12px 32px
      font-size: 1.125rem
      font-weight: 500
      border-radius: 8px
      border: none
      margin-left: 50%
      transform: translateX(-50%)
      transition: .5s ease
  .hidden-roulette
    bottom: -100%
@media only screen and (min-width: 600px) 
  body
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    height: 100svh
    background: none
  .wrap
    .pc-warning
      display: flex
      height: 100%
      align-items: center
      font-size: 2rem
      padding: 0 15%
      text-align: center
    .logo
      display: none
    .roulette-wrapper
      display: none
</style>
