<template>
    <div class="win-card" ref="winCard">
        <img :src="require(`@/assets/jobs/${winning.imageSrc}`)" alt class="image" />
        <p class="name">{{ winning.name }}</p>
        <p class="quote">{{ winning.quote }}</p>
        <p class="desc">{{ winning.desc }}</p>
        <div class="info">
            <p class="prompt">Место работы:</p>
            <p class="value">{{ winning.placeJob }}</p>
        </div>
        <div class="info">
            <p class="prompt">Инвентарь:</p>
            <p class="value">{{ winning.inventory }}</p>
        </div>

        <button class="wheel-more" @click="$emit('wheel-more')">Крутить ещё</button>
    </div>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            document.querySelector('.wheel-more').style.opacity = '1'
        }, 3000);
    },
    props: {
        winning: Object
    }
}
</script>

<style lang='sass' scoped>
@media only screen and (min-width: 208px)
    .win-card
        // position: relative
        background-image: url(@/assets/card_bg.png)
        background-size: cover
        background-position-x: center
        background-repeat: no-repeat
        max-width: 100vw
        height: 100svh
        z-index: 1
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        padding: 32px 20px
        color: #D7D7D7
        text-align: center
        // opacity: 0
        // filter: blur(10px)
        // transition: opacity 1s ease, filter 1s ease
        & > *
            margin: 0
        .image
            // width: 50%
            height: 15%
        .name
            font-size: 1.375rem
            font-weight: 800
            padding: 8px 0
            border-top: 1px solid #32a3dc
            border-bottom: 1px solid #32a3dc
            width: 100%
            margin-top: 16px
        .quote
            color: #BC8857
            font-size: 1.125rem
            font-weight: 900
            width: 80%
            margin-top: 21px
        .desc
            font-size: 1rem
            font-weight: 500
            margin-bottom: 27px
            margin-top: 4px
        .info
            display: flex
            flex-direction: column
            gap: 4px
            margin-bottom: 27px
            & > *
                margin: 0
            .prompt
                color: #BC8857
                font-size: 1rem
                font-weight: 600
            .value
                font-size: 1rem
                font-weight: 500
        .wheel-more
            opacity: 0
            transition: .1s ease
            font-size: 0.875rem
            font-weight: 500
            color: #D7D7D7
            padding: 6px 20px
            background: #BC885729
            border: 1px solid #BC8857
            border-radius: 8px
            margin-top: auto
            margin-bottom: 100px
@media only screen and (min-width: 600px)
    .win-card
        display: none
</style>